import { mapGetters } from 'vuex'
var convert = require('xml-js')
import innondation from './innondation'
import seisme from './seisme'
import radon from './radon'
import installationIndustrielles from './installationIndustrielles'
import movementTerrain from './movementTerrain'
import pollutionSols from './pollutionSols'
import canalisationsMatieresDangereuses from './canalisationsMatieresDangereuses'
import InstallationsNucleaires from './InstallationsNucleaires'
import CavitesSouterraines from './CavitesSouterraines'
import RetraitGonflementsSolsArgileux from './RetraitGonflementsSolsArgileux'
export default {
  mixins: [
    innondation,
    seisme,
    radon,
    installationIndustrielles,
    movementTerrain,
    pollutionSols,
    canalisationsMatieresDangereuses,
    InstallationsNucleaires,
    CavitesSouterraines,
    RetraitGonflementsSolsArgileux,
  ],
  data() {
    return {
      list_risques: [
        {
          key: 'SEISM',
          name: 'Séisme',
          numRisque: 158,
          desc: 'Le séisme de cette commune est très faible.',
          icon: 'SEISMIcon',
          is_exist: false,
          sub_risques: [],
          status: ['très faible', 'faible', 'modérée', 'moyenne', 'forte'],
        },
        {
          key: 'CAN',
          name: 'Transport de marchandises dangereuses',
          numRisque: 226,
          desc: "Cette commune n'est pas située dans une zone de transports de marchandises",
          icon: 'TransportDangereuseIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Avalanche',
          numRisque: 159,
          desc: "Cette commune n'est pas située dans une zone sensible aux avalanches.",
          icon: 'avalancheIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Risques miniers',
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition aux émissions de gaz de mine.",
          icon: 'emissionGazIcon',
          is_exist: false,
          groups: [
            'Mouvements de terrains miniers',
            'Inondations de terrain minier',
          ],
          sub_risques: [
            {
              name: 'Mouvements de terrains miniers',
              numRisque: 134,
            },
            {
              name: 'Mouvements de terrains miniers - Effondrements généralisés',
              numRisque: 252,
            },
            {
              name: 'Mouvements de terrains miniers - Effondrements localisés',
            },
            {
              name: 'Mouvements de terrains miniers - Affaissements progressifs',
            },
            {
              name: 'Mouvements de terrains miniers - Tassements',
              numRisque: 157,
            },
            {
              name: 'Mouvements de terrains miniers - Glissements ou mouvements de pente',
              numRisque: 288,
            },
            {
              name: 'Mouvements de terrains miniers - Coulées',
            },
            {
              name: 'Mouvements de terrains miniers - Écroulements rocheux',
            },
            {
              name: 'Inondations de terrain minier',
            },
            {
              name: 'Inondations de terrain minier - Pollution des eaux souterraines et de surface',
            },
            {
              name: 'Inondations de terrain minier - Pollution des sédiments et des sols',
            },
            {
              name: 'Emissions en surface de gaz de mine',
            },
          ],
        },
        {
          key: '', //check
          name: 'Engins de guerre',
          numRisque: 231,
          desc: "Cette commune n'est pas située dans une zone de risques de découvertes d’engins de guerre.",
          icon: 'enginGuerreIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', //check
          name: 'Feu de forêt',
          numRisque: 166,
          desc: "Cette commune n'est pas située dans une zone sensible aux feux de forêts.",
          icon: 'feuForetIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: 'INN',
          name: 'Inondation',
          plural: 'Inondations',
          numRisque: 140,
          //  Elle est également soumise à un PPRN (Plan de Prévention des Risques Naturels Prévisibles) ou un PSS (Plan de Surfaces Submersibles).
          desc: "Cette commune n'est pas située dans une zone sensible aux inondations.",
          icon: 'INNIcon',
          is_exist: false,
          sub_risques: [
            {
              name: "Inondation - Par une crue (débordement de cours d'eau)",
            },
            {
              name: 'Inondation - Par une crue à débordement lent de cours d’eau',
            },
            {
              name: 'Inondation - Par une crue torrentielle ou à montée rapide de cours d’eau',
              numRisque: 180,
            },
            {
              name: 'Inondation - Par ruissellement et coulée de boue',
            },
            {
              name: 'Inondation - Par lave torrentielle (torrent et talweg)',
            },
            {
              name: 'Inondation - Par remontées de nappes naturelles',
              numRisque: 285,
            },
            {
              name: 'Inondation - Par submersion marine',
              numRisque: 286,
            },
          ],
        },
        {
          key: 'MVMT',
          name: 'Mouvement de terrain',
          plural: 'Mouvements de terrain',
          numRisque: 134,
          desc: "Cette commune n'est pas située dans une zone sensible aux mouvements de terrain.",
          icon: 'MVMTIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Mouvement de terrain - Affaissements et effondrements liés aux cavités souterraines (hors mines)',
              numRisque: 135,
            },
            {
              name: 'Mouvement de terrain - Effondrement',
            },
            {
              name: 'Mouvement de terrain - Eboulement, chutes de pierres et de blocs',
              numRisque: 143,
            },
            {
              name: 'Mouvement de terrain - Glissement de terrain',
              numRisque: 147,
            },
            {
              name: 'Mouvement de terrain - Avancée dunaire',
              numRisque: 152,
            },
            {
              name: 'Mouvement de terrain - Recul du trait de côte et de falaises',
              numRisque: 153,
            },
            {
              name: 'Mouvement de terrain - Tassements différentiels',
              numRisque: 157,
            },
          ],
        },
        {
          key: 'NUC',
          name: 'Nucléaire',
          numRisque: 230,
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition au nucléaire.",
          icon: 'NUCIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', // check
          name: 'Phénomène atmosphérique',
          plural: 'Phénomènes atmosphériques suivants',
          numRisque: 167,
          desc: "Cette commune n'est pas située dans une zone sensible à des phénomènes météorologiques (vents violents, grêles, neige, foudre, etc).",
          icon: 'PhenomenesMeteorologiquesIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Phénomènes météorologiques - Cyclone/ouragan (vent)',
              numRisque: 168,
            },
            {
              name: 'Phénomènes météorologiques - Tempête et grains (vent)',
              numRisque: 169,
            },
            {
              name: 'Phénomènes météorologiques - Foudre',
            },
            {
              name: 'Phénomènes météorologiques - Grêle',
            },
            {
              name: 'Phénomènes météorologiques - Neige et Pluies verglaçantes',
            },
          ],
        },
        {
          key: 'RADON',
          name: 'Radon',
          numRisque: 229,
          desc: "Cette commune n'est pas située dans une zone sensible à l’exposition au Radon.",
          icon: 'RADONIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: 'INST',
          name: 'Risque industriel',
          plural: 'Risques industriels',
          numRisque: 215,
          desc: "Cette commune n'est pas située dans une zone sensible aux risques industriels (Effet toxique, thermique, etc).",
          icon: 'INSTIcon',
          is_exist: false,
          sub_risques: [
            {
              name: 'Risque industriel - Effet thermique',
              numRisque: 236,
            },
            {
              name: 'Risque industriel - Effet de surpression',
              numRisque: 237,
            },
            {
              name: 'Risque industriel - Effet toxique',
              numRisque: 238,
            },
            {
              name: 'Risque industriel - Effet de projection',
              numRisque: 295,
            },
          ],
        },
        {
          key: '', //check
          name: 'Rupture de barrage',
          desc: "Cette commune n'est pas située dans une zone sensible à la rupture de barrage.",
          icon: 'RuptureBarrageIcon',
          is_exist: false,
          sub_risques: [],
        },
        {
          key: '', //check
          name: 'Eruption volcanique',
          numRisque: 160,
          desc: "Cette commune n'est pas située dans une zone volcanique.",
          icon: 'feuForetIcon',
          is_exist: false,
          sub_risques: [],
        },
      ],
    }
  },

  methods: {
    prepareDataRapportTechnique(risques) {
      let total_catastrophes_naturelles = 0
      let catastrophes_naturelles = {}
      let nbr_risques_exist = 0
      this.list_risques.forEach((item) => {
        item.is_exist = this.check_risque(risques, item)
      })
      this.checkDescription(risques)
      if (risques.catnat) {
        total_catastrophes_naturelles = risques.catnat.length
        catastrophes_naturelles = _.groupBy(risques.catnat, 'libRisqueJO')
      }
      this.list_risques.sort(function (x, y) {
        return x.is_exist === y.is_exist ? 0 : x.is_exist ? -1 : 1
      })
      nbr_risques_exist = this.list_risques.filter(
        (item) => item.is_exist === true,
      ).length
      let temp_risque = {
        resume: {
          list_risques_commune: this.list_risques,
          total_risques_commune_exist: nbr_risques_exist,
          total_catastrophes_naturelles: total_catastrophes_naturelles,
          catastrophes_naturelles: catastrophes_naturelles,
        },
        details_risques: this.getDetailsRisque(risques),
      }
      this.moreDetailsEachRisque(risques, temp_risque.details_risques)
      return temp_risque
    },
    moreDetailsEachRisque(risques, details_risques) {
      this.setSeisme(risques, details_risques.SEISM)
      this.setInnodation(risques, details_risques.INN)
      this.setRadon(risques, details_risques.RADON)
      this.setInstallationIndustrielles(risques, details_risques.INST)
      this.setMouvementTerrain(risques, details_risques.MVMT)
      this.setPollutionSols(risques, details_risques.POL)
      this.setCanalisationsMatieresDangereuses(risques, details_risques.CAN)
      this.setInstallationsNucleaires(risques, details_risques.NUC)
      this.setCavitesSouterraines(risques, details_risques.CAV)
      this.setRetraitGonflementsSolsArgileux(risques, details_risques.RETRAIT)
    },

    check_risque(risques, risque) {
      return risques.risqueDetail.some((item) => {
        return item.libRisqueLong === risque.name
          ? item.libRisqueLong === risque.name
          : risque.sub_risques.some(
              (sub_risque) => item.libRisqueLong === sub_risque.name,
            )
      })
    },

    checkDescription(risques) {
      this.list_risques.forEach((risque) => {
        risque.existing_subRisques = []
        if (risque.is_exist || risque.key === 'SEISM') {
          if (risque.key === 'SEISM') {
            let code_zone =
              risques &&
              risques.statut_zone_sismicite &&
              risques.statut_zone_sismicite.data[0]
                ? risques.statut_zone_sismicite.data[0].code_zone
                : null
            if (code_zone) {
              risque.is_exist = code_zone > 2
              risque.desc = `Le ${risque.name.toLowerCase()} de cette commune est ${
                risque.status[parseInt(code_zone) - 1]
              } (${code_zone}/5)`
            } else {
              let risque_api = this.findByNameRisque(risques, risque.name)
              let is_exist_risque_api_sismicite =
                risque_api !== undefined &&
                risque_api.hasOwnProperty('sismicite')
                  ? risque_api.sismicite
                  : null
              if (is_exist_risque_api_sismicite) {
                let sismicite = risque_api.sismicite
                if (sismicite) {
                  risque.desc = `Le ${risque.name.toLowerCase()} de cette commune est ${
                    risque.status[sismicite - 1]
                  } (${sismicite}/5)`
                }
              }
            }
          } else {
            if (risque.sub_risques.length) {
              risque.existing_subRisques = risque.sub_risques
                .filter((item) => {
                  return this.findByNameRisque(risques, item.name)
                })
                .map((item) => item.name)
                .map((item) => {
                  if (risque.groups) {
                    risque.groups.forEach((group) => {
                      item = `${item.replaceAll(group + ' - ', '')}`
                    })
                  } else {
                    item = item.replaceAll(risque.name + ' - ', '')
                  }
                  return item
                })
            }
            risque.desc = risque.desc.replace("n'est pas", 'est')
          }
        }
        if (risques.radon.length && risque.numRisque == 229) {
          risque.desc = `Cette commune est située dans une zone sensible au ${risque.name.toLowerCase()} de catégorie ${
            risques.radon[0].classePotentiel
          }.`
          if (['2', '3'].includes(risques.radon[0].classePotentiel)) {
            risque.is_exist = true
          }
        }
      })
    },
    findByNameRisque(risques, name) {
      return risques.risqueDetail.find((item) => {
        if (item.libRisqueLong === name) {
          return item
        }
      })
    },
    getDetailsRisque(risques) {
      let details_risques = {
        SEISM: null,
        RETRAIT: null,
        RADON: null,
        INN: null,
        CAN: null,
        CAV: null,
        INST: null,
        MVMT: null,
        NUC: null,
        POL: null,
        RTM: null,
      }
      Object.keys(risques.definitions_risques).forEach((key_risque) => {
        let definitions = []
        let definitions_key = [
          'definitionPol',
          'definition2Lien',
          'definition',
          'definition2',
          'definition3',
        ]
        Object.keys(risques.definitions_risques[key_risque]).forEach(
          (key_defini) => {
            if (definitions_key.includes(key_defini))
              definitions.push(
                risques.definitions_risques[key_risque][key_defini],
              )
          },
        )
        let risque_obj = {
          key: key_risque,
          name: risques.definitions_risques[key_risque].name,
          definitions: definitions,
          sub_title: [],
        }
        this.insertDetailsRisque({
          obj: risques,
          key_risque: key_risque,
          key: 'alea',
          risque_obj: risque_obj,
        })
        this.insertDetailsRisque({
          obj: risques,
          key_risque: key_risque,
          key: 'ppr',
          risque_obj: risque_obj,
        })

        details_risques[risque_obj.key] = risque_obj
      })
      return details_risques
    },

    insertDetailsRisque(params) {
      if (params.obj.definitions_risques[params.key_risque][params.key]) {
        let alea
        if (params.key === 'alea') {
          alea =
            params.obj.definitions_risques[params.key_risque][params.key][0]
        } else {
          if (!params.obj.definitions_risques[params.key_risque][params.key])
            return
          alea = params.obj.definitions_risques[params.key_risque][params.key]
        }
        let alea_obj = {
          key: params.key,
          title: alea.title,
          label: alea.label,
          rayon: alea.rayon,
          layer: alea.layer[0],
          properties: alea.properties ? alea.properties.toString() : '',
          value: '',
          descr: alea.descr,
          codes: params.key === 'ppr' ? alea.codes : [],
        }
        params.risque_obj.sub_title.push(alea_obj)
      }
    },
    xmlToJson(data_xml) {
      let xmlDoc = data_xml
      let data = convert.xml2json(xmlDoc, {
        compact: true,
        spaces: 4,
      })
      let data_json = JSON.parse(data)
      return data_json
    },
    notEmptyObject(someObject) {
      return someObject == null || !Object.keys(someObject).length
        ? false
        : true
    },
    setValuesTable(data, ...keys) {
      let result = []
      data.forEach((val) => {
        let obj = []
        keys.forEach((key) => {
          let valToPush
          if (typeof key !== 'string') {
            valToPush =
              this.checkKey(val, key[0]) + ' - ' + this.checkKey(val, key[1])
          } else {
            valToPush = this.checkKey(val, key)
          }
          obj.push(valToPush)
        })
        result.push(obj)
      })
      return result
    },
    checkKey(val, key) {
      return val[key] === undefined ? val['properties'][key] : val[key]
    },
  },
  computed: {
    ...mapGetters({}),
  },
}

<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Rapport technique"
        :progress_section="progress_section.rapport_technique"
      />
    </template>
    <template #current_page>Rapport technique</template>
    <template #body>
      <div class="flex" v-if="is_api_risque_not_working">
        les informations des risques non disponible
      </div>
      <div
        class="flex flex-col"
        v-else-if="rapport_technique.resume && all_data_risque_loaded"
      >
        <button class="btn-green mt-8 py-2 px-4 w-fit" @click="updateRisk()">
          Mise à jour des risques
        </button>
        <pro-tabs :tabs="tabs" class="mt-8">
          <pro-tab slot-scope="{ tabs, selectTab, currentTab }">
            <div class="flex flex-row sm:flex-col">
              <div
                v-for="tab in tabs"
                :key="tab.name"
                class="mr-24 text-sm font-bold capitalize text-gray-800 sm:my-2"
                :class="{
                  'border-b-tab text-promy-green-300': tab.selected,
                }"
              >
                <a href="#" @click="selectTab(tab)">{{ tab.name }}</a>
              </div>
            </div>
            <hr class="mt-2" />
            <div class="mt-8">
              <component
                :is="currentTab"
                :rapport_technique="rapport_technique"
              />
            </div>
          </pro-tab>
        </pro-tabs>
      </div>

      <div v-else class="mt-8 relative h-55 flex justify-center">
        <loader
          class="absolute z-20"
          :size_bg_promy="0"
          :color="'#008279'"
        ></loader>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
import risques from '@/mixins/dossierPromoteurs/RapportTechnique/risques'
import Resume from './Resumer/index'
import Inondation from './inondation'
import Sismicite from './sismicite'
import GeologieDeSol from './geologie-de-sol'
import Radon from './radon'
import InstallationsIndustrielles from './installations-industrielles'
import MouvementDeTerrain from './mouvement-de-terrain'
import PollutionDesSols from './pollution-des-sols'
import helpers from '@/mixins/helpers'

export default {
  components: {
    Resume,
    Inondation,
    Sismicite,
    GeologieDeSol,
    Radon,
    InstallationsIndustrielles,
    MouvementDeTerrain,
    PollutionDesSols,
  },
  mixins: [progress, risques, helpers],

  data() {
    return {
      is_api_risque_not_working: false,
      is_save_DP: false,
      rapport_technique: {
        resume: null,
      },
      tabs: [
        {
          name: 'Résumé',
          selected: true,
        },
        {
          name: 'Inondation',
          selected: false,
        },
        {
          name: 'Sismicité',
          selected: false,
        },
        // {
        //   name: "Géologie de sol",
        //   selected: false,
        // },
        {
          name: 'Radon',
          selected: false,
        },
        {
          name: 'Installations industrielles',
          selected: false,
        },
        {
          name: 'Mouvement de terrain',
          selected: false,
        },
        {
          name: 'Pollution des sols',
          selected: false,
        },
      ],
      all_data_risque_loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      rapportTechniqueStore: 'dossierpromoteur/getRapportTechnique',
      progress_section: 'dossierpromoteur/getProgressSection',
      informationsStore: 'dossierpromoteur/getinfos',
      risques_cross_api_is_loading: 'risques/risques_cross_api_is_loading',
      risques: 'risques/risques',
    }),
    risques_is_loaded() {
      return (
        this.is_canalisations_matieres_dangereuses_loaded &&
        this.is_cavites_souterraines_loaded &&
        this.is_installation_industrielles_loaded &&
        this.is_installations_nucleaires_loaded &&
        this.is_mouvement_terrain_loaded &&
        this.is_pollution_sols_loaded &&
        this.is_retrait_gonflements_sols_argileux_loaded
      )
    },
  },
  methods: {
    updateRisk() {
      this.rapport_technique = { resume: null }
      this.$store.commit('dossierpromoteur/setRapportTechnique', {})
      this.$store
        .dispatch('risques/getRisques', {
          code_insee: this.informationsStore.code_insee,
          lat: this.informationsStore.center_parcelle_principale[0],
          long: this.informationsStore.center_parcelle_principale[1],
        })
        .catch((err) => {
          console.log('risques not found ', err)
        })
    },
    saveInfos() {},
  },
  watch: {
    risques_cross_api_is_loading: {
      immediate: true,
      handler(is_loading) {
        this.rapport_technique = Object.assign(
          {},
          this.rapport_technique,
          this.rapportTechniqueStore,
        )
        if (!this.rapport_technique.resume) {
          this.$store.commit('dossierpromoteur/setProgressSection', {
            section: 'rapport_technique',
            value: 0,
          })
        }
        if (!is_loading) {
          if (!this.rapport_technique.resume) {
            try {
              this.rapport_technique = this.prepareDataRapportTechnique(
                this.risques,
              )
            } catch (err) {
              console.log(err)
              this.is_api_risque_not_working = true
              this.all_data_risque_loaded = true
            }
          } else {
            this.all_data_risque_loaded = true
          }
        }
      },
    },
    risques_is_loaded(is_loaded) {
      if (is_loaded && !this.is_api_risque_not_working) {
        this.$store.commit(
          'dossierpromoteur/setRapportTechnique',
          this.rapport_technique,
        )
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'rapport_technique',
          value: 100,
        })
        this.all_data_risque_loaded = true
      }
    },
  },
}
</script>
